import { Pane } from "evergreen-ui";

export const AppBox = ({ children }) => {
  return (
    <Pane
      width="100%"
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      background="#2a2b2b"
     
     
      
    >
      <Pane
        width={500}
        maxWidth="95%"
        marginTop={-120}
        display="flex"
        flexDirection="column"
        gap={20}
        padding={50}
        borderRadius={5}
        color="white"
        background="#2a2b2b"
         boxShadow="#5C85FF 0px 2px 15px;"
      >
        {children}
      </Pane>
    </Pane>
  );
};
