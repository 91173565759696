import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Navbar,
  Footer,
  Home,
  Echo,
  Illustrate,
  ColdEmail
} from "./components";

function App() {
  return (
    <>
      <Navbar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/echo" element={<Echo />} />
          <Route path="/cold-email-ai" element={<ColdEmail />} />
          <Route path="/illustrate-ai" element={<Illustrate />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
