import { Pane, Heading, Label, Link } from "evergreen-ui";
import { theme } from "../theme";

export const Navbar = () => {
  return (
    <Pane
      elevation={1}
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height={60}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      padding={20}
      background={theme.colors.black}
    >
      <Link
        href="/"
        width="fit-content"
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={5}
      >
        <img
          alt="</>"
          src="./assets/bluegreen_icon.png"
          width={25}
          height={25}
          style={{ borderRadius: "50px" }}
        />

        <Pane display="flex" alignItems="center" gap={3}>
          <Heading color="white" fontSize="1.2rem" lineHeight={1.4}>
            <Label
              cursor="inherit"
              fontSize="inherit"
              color={theme.colors.blue}
            >
              Blue
            </Label>
            <Label
              cursor="inherit"
              fontSize="inherit"
              color={theme.colors.green}
            >
              green
            </Label>
          </Heading>
        </Pane>
      </Link>
      <Link style={{color: "white"}} href="mailto:bluegreendev5@gmail.com">Contact Us</Link>
    </Pane>
  );
};
