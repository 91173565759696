import { Pane } from "evergreen-ui";

const ulStyle = { display: "flex", flexDirection: "column", gap: "15px" };

export const Illustrate = () => {
  return (
    <Pane
      paddingTop={130}
      paddingBottom={300}
      paddingX={50}
      background="whitesmoke"
    >
      <h1 style={{ fontSize: 40, lineHeight: 0.5 }}>Illustration AI</h1>
      <h3>Chrome Extension</h3>
      <p>Last updated January 1, {new Date().getFullYear()}</p>
      <h1>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Thank you for using <strong>Illustrate AI</strong>! Your privacy is
        important, and this policy explains how your data is handled when using
        the extension.
      </p>

      <h2>2. How the Extension Works</h2>
      <ul style={ulStyle}>
        <li>The extension allows you to convert images into illustrations.</li>
        <li>
          When activated, the extension processes the clicked image locally or
          sends it to a secure server for illustration generation.
        </li>
        <li>
          No images are stored permanently; they are only used for the
          illustration process.
        </li>
        <li>Illustrated images are displayed within your browser in real-time.</li>
      </ul>

      <h2>3. Data Collection & Storage</h2>
      <ul style={ulStyle}>
        <li>User emails are collected when signing up for a paid plan.</li>
        <li>No images or user data are stored remotely after processing.</li>
        <li>
          No personal information, images, or illustration data are stored in
          any external database.
        </li>
        <li>
          User preferences and settings are stored locally on your device.
        </li>
        <li>We do not sell or share your data with third parties.</li>
      </ul>

      <h2>4. Third-Party Services</h2>
      <ul style={ulStyle}>
        <li>
          This extension may use AI services to generate illustrations, and
          Stripe for payment processing.
        </li>
        <li>
          These third-party services may process and store data according to
          their own privacy policies.
        </li>
        <ol style={ulStyle}>
          <li>
            <a href="https://openai.com/policies/privacy-policy/">
              Open AI - Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://stripe.com/privacy">Stripe - Privacy Policy</a>
          </li>
        </ol>
      </ul>

      <h2>5. Contact</h2>
      <p>
        If you have any questions about this Privacy Policy, please feel free
        to <a href="mailto:bluegreendev5@gmail.com">contact us</a>.
      </p>
    </Pane>
  );
};
