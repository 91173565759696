import { Pane, Link, Text, ArrowTopRightIcon } from "evergreen-ui";
import { theme } from "../../../theme";

export const Links = () => {
  const Href = ({ href, text, target }) => {
    return (
      <Link width="fit-content" href={href} target={target}>
        <Pane display="flex" flexDirection="row" alignItems="center" gap={5}>
          <Text color={theme.colors.green}>{text}</Text>
          <ArrowTopRightIcon color="#5C85FF" size={10} />
        </Pane>
      </Link>
    );
  };

  return (
    <Pane display="flex" flexDirection="column" gap={10}>
      <Href href="https://kudos.cam" text="Kudos (App)" target="_blank" />
      <Href
        href="https://chromewebstore.google.com/detail/echo-ai/iibchkaenpekfflachpbggikccoohpil"
        text="Echo AI (Chrome Extension)"
        target="_blank"
      />
      <Href
        href="/cold-email-ai"
        text="Cold Email AI (Chrome Extension)"
        target="_self"
      />
      <Href
        href="https://chromewebstore.google.com/detail/illustrate-ai/ggbbapgoappchnheijbnplndjhnbonlh"
        text="Illustrate AI (Chrome Extension)"
        target="_blank"
      />
    </Pane>
  );
};
