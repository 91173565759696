import { Pane } from "evergreen-ui";

const ulStyle = { display: "flex", flexDirection: "column", gap: "15px" };

export const Echo = () => {
  return (
    <Pane
      paddingTop={130}
      paddingBottom={300}
      paddingX={50}
      background="whitesmoke"
    >
      <h1 style={{ fontSize: 40, lineHeight: 0.5 }}>Echo AI</h1>
      <h3>Chrome Extension</h3>
      <p>Last updated January 1, {new Date().getFullYear()}</p>
      <h1>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Thank you for using <strong>Echo AI</strong>! Your privacy is important,
        and this policy explains how your data is handled when using the
        extension.
      </p>

      <h2>2. How the Extension Works</h2>
      <ul style={ulStyle}>
        <li>
          The extension allows you to share your browser tab’s media inputs.
        </li>
        <li>
          Audio (not video) is sent in small chunks to a server for real-time
          transcription.
        </li>
        <li>
          The transcribed text is processed by an AI model to generate
          actionable suggestions.
        </li>
        <li>
          These suggestions are displayed as Chrome notifications in real-time.
        </li>
        <li>
          Once the session ends, temporary data stored locally can be optionally
          downloaded before being permanently deleted.
        </li>
      </ul>

      <h2>3. Data Collection & Storage</h2>
      <ul style={ulStyle}>
        <li>User emails are collected when signing up for a paid plan.</li>
        <li>No other user data is collected or stored remotely.</li>
        <li>
          No personal information, transcripts, or media data are stored in any
          external database.
        </li>
        <li>
          User preferences and session data are stored locally on your device.
        </li>
        <li>We do not sell your data to third parties.</li>
      </ul>

      <h2>4. Third-Party Services</h2>
      <ul style={ulStyle}>
        <li>
          This extension uses Open AI for transcription and AI processing, and
          Stripe for payment processing.
        </li>
        <li>
          These third-party services may process and store data according to
          their own privacy policies.
        </li>
        <ol style={ulStyle}>
          <li>
            <a href="https://openai.com/policies/privacy-policy/">
              Open AI - Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://stripe.com/privacy">Stripe - Privacy Policy</a>
          </li>
        </ol>
      </ul>

      <h2>5. Contact</h2>
      <p>
        If you have any questions about this Privacy Policy, please feel free to{" "}
        <a href="mailto:bluegreendev5@gmail.com">contact us</a>.
      </p>
    </Pane>
  );
};
