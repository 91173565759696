import { Pane } from "evergreen-ui";

const ulStyle = { display: "flex", flexDirection: "column", gap: "15px" };

export const ColdEmail = () => {
  return (
    <Pane
      paddingTop={130}
      paddingBottom={300}
      paddingX={50}
      background="whitesmoke"
    >
      <h1 style={{ fontSize: 40, lineHeight: 0.5 }}>Cold Email AI</h1>
      <h3>Chrome Extension</h3>
      <p>Last updated January 1, {new Date().getFullYear()}</p>
      <h1>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Thank you for using <strong>Cold Email AI</strong>! Your privacy is
        important to us. This policy explains how your data is handled when
        using the extension.
      </p>

      <h2>2. How the Extension Works</h2>
      <ul style={ulStyle}>
        <li>The extension helps users generate and personalize cold emails.</li>
        <li>
          It extracts publicly available emails and auto-generates email drafts
          based on user inputs.
        </li>
        <li>
          All email generation occurs locally within your browser session, and
          no data is stored permanently.
        </li>
      </ul>

      <h2>3. Data Collection & Storage</h2>
      <ul style={ulStyle}>
        <li>The extension does not collect or store personal information.</li>
        <li>
          Extracted emails and generated content are used solely within the
          user's current session.
        </li>
        <li>
          User preferences and settings are stored locally on your device.
        </li>
        <li>We do not sell or share your data with third parties.</li>
      </ul>

      <h2>4. Third-Party Services</h2>
      <ul style={ulStyle}>
        <li>
          The extension may use AI services to generate email content, and
          Stripe for payment processing.
        </li>
        <li>
          These third-party services may process and store data according to
          their own privacy policies.
        </li>
        <ol style={ulStyle}>
          <li>
            <a href="https://openai.com/policies/privacy-policy/">
              OpenAI - Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://stripe.com/privacy">Stripe - Privacy Policy</a>
          </li>
        </ol>
      </ul>

      <h2>5. Contact</h2>
      <p>
        If you have any questions about this Privacy Policy, please feel free to
        <a href="mailto:bluegreendev5@gmail.com"> contact us</a>.
      </p>
    </Pane>
  );
};
