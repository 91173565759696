import { AppBox, Title, SubTitle, Links, SuccessDialog } from "./components";
export const Home = () => {
  return (
    <AppBox>
      <Title />
      <SubTitle />
      <Links />
      <SuccessDialog />
    </AppBox>
  );
};
