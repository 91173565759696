import { Pane, Heading, Label } from "evergreen-ui";
import { theme } from "../../../theme";

export const Title = () => {
  return (
    <Pane display="flex" alignItems="center" gap={3} >
      <Heading color="inherit" fontSize="1.5rem" lineHeight={1.4}>
        <Label fontSize="inherit" color={theme.colors.blue}>
          Blue
        </Label>
        <Label fontSize="inherit" color={theme.colors.green}>
          green
        </Label>{" "}
        Development
      </Heading>
    </Pane>
  );
};
