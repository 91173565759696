import { useEffect, useState } from "react";
import { Alert, Dialog, Text, IconButton, CrossIcon } from "evergreen-ui";
import { theme } from "../../../theme";

const extensions = ["echo", "illustrate-ai", "cold-email-ai"];

export const SuccessDialog = () => {
  const [ext, setExt] = useState("");
  const [showDialog, setShowDialog] = useState(false);

  const onParseUrl = (input) => {
    const urlString = window.location.href;

    const url = new URL(urlString);
    const output = url.searchParams.get(`${input}`);

    const path = window.location.pathname;

    if (extensions.includes(output)) {
      window.history.replaceState({}, document.title, path + "");
      return onParseParam(output);
    }
    return false;
  };

  const onParseParam = (x) => {
    switch (x) {
      case "echo":
        setExt("Echo AI");
        setShowDialog(true);
        break;
      case "illustrate-ai":
        setExt("Illustrate AI");
        setShowDialog(true);
        break;
      case "cold-email-ai":
        setExt("Cold Email AI");
        setShowDialog(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onParseUrl("x");
  }, []);

  return (
    <Dialog
      title=""
      isShown={showDialog}
      hasHeader={false}
      hasFooter={false}
      contentContainerProps={{ padding: 0 }}
      overlayProps={{
        background: theme.colors.black,
      }}
    >
      <Alert
        title={`${ext} - Payment was successful.`}
        intent="success"
        height={100}
      >
        <br />
        <Text>You now have full access to your Chrome Extension.</Text>

        <IconButton
          icon={CrossIcon}
          position="absolute"
          top={0}
          right={0}
          margin={5}
          onClick={() => setShowDialog(false)}
        />
      </Alert>
    </Dialog>
  );
};
